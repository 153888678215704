import React from 'react';

const Image = ({ src, width, height, alt, title, className }) => {
  return <picture>
    <source srcSet={ src + '.webp'} type="image/webp"/>
    <source srcSet={ src + '.png'} type="image/png"/>
    <img src={ src + '.png'} width={width} height={height} alt={alt} title={title} className={className} />
  </picture>;
};

export default Image;
