import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row, ListGroup } from 'react-bootstrap';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Image from '../../components/Image';

import styles from './styles.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();

  const seoKeywords = [
    'UK Data Centre',
    'Colchester Data Centre',
    'Essex Data Centre',
    'Data Centre in Colchester',
    'Data Centre in Ardleigh',
    'Data Centre in Essex',
    'Data Centre near Ipswich',
    'Data Centre near Chelmsford',
    'Data Centre near London',
    'Data Centre near Clacton-on-Sea',
    'Data Centre near Braintree',
    'Data Centre near Bury St Edmunds',
    'Data Centre near Norwich',
  ];

  return <footer className={styles.footer}>
    <Container>
      <Row className="pt-5 pb-4">
        <Col xs={12} md={12} lg={4} className="fs-8 mb-4">
          <Image src="/images/logo192" width={150} height={126} alt="Purple IO Ltd" />
          <p className="mt-4">
            {year} © Purple IO Ltd<br />
            Founded 2013
          </p>
          <p className="mb-1 text-grey">
            <small>
              Purple IO Ltd t/a sFTP Client and sFTP Server.<br />
              Registered in England & Wales.<br />
              a UK company based in Colchester.
            </small>
          </p>
          <p className="mb-3 text-grey">
            <small>
              <span className="text-white">Reg No.</span>
              13387995
            </small>
          </p>
          <p className="mb-2">
            <span className="me-2 text-tertiary">
              <FontAwesomeIcon icon="envelope" className="me-1" />
              Enquiries
            </span>
            hello@purpleio.uk
          </p>
          <p>
            <span className="me-2 text-tertiary">
              <FontAwesomeIcon icon="envelope" className="me-1" />
              Support
            </span>
            help@purpleio.uk
          </p>
        </Col>
        <Col xs={12} md={6} lg={true} className="mb-4">
          <h5 className="text-tertiary fs-6">
            <FontAwesomeIcon icon="window-maximize" className="text-tertiary me-2" />
            sFTP Client
          </h5>
          <ListGroup variant="flush">
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/client">Overview</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/client/features">Features</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/client/pricing">Pricing</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/client/release">Release Notes</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/client/compare">Market Comparison</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/client/faqs">FAQ's</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/client/download">Download</ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs={12} md={6} lg={true} className="mb-4">
          <h5 className="text-tertiary fs-6">
            <FontAwesomeIcon icon="server" className="text-tertiary me-2" />
            sFTP Server
          </h5>
          <ListGroup variant="flush">
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/server">Overview</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/server/features">Features</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/server/pricing">Pricing</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/server/release">Release Notes</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/server/faqs">FAQ's</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/server/download">Download</ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs={12} md={6} lg={true} className="mb-4">
          <h5 className="text-tertiary fs-6">
            <FontAwesomeIcon icon="building" className="text-tertiary me-2" />
            Company
          </h5>
          <ListGroup variant="flush">
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/company/about">Overview</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/company/data-centre">Data Centre</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/company/careers">Jobs / Careers</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/company/customers">Customers</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/company/contact">Contact</ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs={12} md={6} lg={true} className="mb-4">
          <h5 className="text-tertiary fs-6">
            <FontAwesomeIcon icon="folders" className="text-tertiary me-2" />
            Resources
          </h5>
          <ListGroup variant="flush">
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action target="_blank" rel="noreferrer" href="https://community.purpleio.uk">Community</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/blog">Blog / News</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/security">Security Centre</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action target="_blank" rel="noreferrer" href="https://help.purpleio.uk">Documentation</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/branding">Media Kit</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action as={Link} to="/partners">Partners</ListGroup.Item>
            <ListGroup.Item variant="tertiary" className={styles.listGroupItemTertiary} action target="_blank" rel="noreferrer" href="https://climate.stripe.com/cwCm3F">Carbon Removal</ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
    <Row className={styles.bottom}>
      <Col>
        <Container className="pt-4 pb-4">
          <Row className="pb-4">
            <Col xs={12} md={9} className="text-center text-md-start">
              <Button variant="outline" className="m-1 white-text" target="_blank" rel="noreferrer" href="https://status.purpleio.uk">Status</Button>
              <Button variant="outline" className="m-1 white-text" as={Link} to="/legal">Legal Stuff</Button>
              <Button variant="outline" className="m-1 white-text" as={Link} to="/legal/privacy">Privacy Policy</Button>
              <Button variant="outline" className="m-1 white-text" as={Link} to="/legal/cookie">Cookie Policy</Button>
            </Col>
            <Col xs={12} md={3} className="p-0 text-center text-md-end">
              <Button variant="outline" className="m-1 fs-5 white-text" title="Facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/purpleio/">
                <FontAwesomeIcon type="brand" icon="facebook" />
              </Button>
              <Button variant="outline" className="m-1 fs-5 white-text" title="Linked In" target="_blank" rel="noreferrer" href="https://www.linkedin.com/companies/PurpleIO">
                <FontAwesomeIcon type="brand" icon="linkedin" />
              </Button>
              <Button variant="outline" className="m-1 fs-5 white-text" title="Twitter" target="_blank" rel="noreferrer" href="https://twitter.com/PurpleIOLtd">
                <FontAwesomeIcon type="brand" icon="twitter" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="text-center text-md-left">
              {seoKeywords.map((keyword) => (
                <Link key={keyword.toLowerCase().replace(' ', '-')} to="/company/data-centre" className="fs-8 inline-block d-inline-block p-2 pt-0 pb-1 text-grey">
                  <small>{ keyword }</small>
                </Link>
              ))}
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  </footer>;
};

export default Footer;
