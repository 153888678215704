import React, { createContext, useState } from 'react'
import InitOrderStateData from '../data/InitOrderStateData';
import Cache from '../system/Cache';

export const OrderContext = createContext({
  state: InitOrderStateData,
  dispatch: () => null
})

export const OrderProvider = ({ children }) => {
  const cache = new Cache();

  const [orderState, setOrderState] = useState(() => {
    const localOrderState = cache.get('order');

    if (null === localOrderState) {
      return InitOrderStateData;
    }

    return localOrderState;
  });

  const dispatchOrder = (order) => {
    cache.set('order', order);
    setOrderState(order);
  };

  return <OrderContext.Provider value={[ orderState, dispatchOrder ]}>
    { children }
  </OrderContext.Provider>;
}
