import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Index from '../layout/Header';
import Footer from '../layout/Footer';
import { ThemeProvider } from 'react-bootstrap';

import Meta from '../components/Meta';
import MetaData from '../data/MetaData';

const Layout = () => {
  const location = useLocation();
  const metaData = MetaData(location.pathname);
  const [
    metaTitle,
    setMetaTitle,
    metaDescription,
    setMetaDescription
  ] = Meta();

  useEffect(() => {
    // Update Meta Title
    if (undefined === metaTitle ||
      0 === metaTitle.length || (
      0 !== metaData?.title.length &&
      metaTitle !== metaData?.title
    )) {
      setMetaTitle(metaData?.title);
    }

    // Update Meta Description
    if (undefined === metaDescription ||
      0 === metaDescription.length || (
      0 !== metaData?.description.length &&
      metaDescription !== metaData?.description
    )) {
      setMetaDescription(metaData?.description);
    }
  });

  return (
    <ThemeProvider
      breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
      minBreakpoint="xs"
    >
      <Index />

      <main>
        <Outlet />
      </main>

      <Footer />
    </ThemeProvider>
  )
};

export default Layout;
