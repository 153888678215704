import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Button, Container, Nav, Navbar, NavDropdown, Offcanvas, Row, Col } from 'react-bootstrap';
import { OrderContext } from '../../contexts/Order';
import Image from '../../components/Image';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';

import '../../scss/layout/header.scss';

// import styles from './styles.module.scss';

const Header = () => {
  const [orderState] = useContext(OrderContext);

  const totalOrderQty = orderState.plans.reduce((a, b) => a + parseInt(b['quantity']), 0);

  return <>
    {[false, 'lg'].map((expand) => (
      <Navbar
        key={expand ? 'desktop-nav' : 'mobile-nav'}
        expand={expand}
        sticky="top"
        className={expand ? "d-none d-" + expand + "-block" : "d-block d-lg-none"}
      >
        <Container fluid>
          <Navbar.Brand as={NavLink} to="/">
            <Image src="/images/logo192" width={64} height={54} alt="Purple IO"></Image>
          </Navbar.Brand>
          <Navbar.Brand as={NavLink} to="/">
            <span className="fs-4 lh-1 d-block text-tertiary">Purple IO</span>
            <small className="fs-6">UK Data Center</small>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={(expand ? '' : 'mobile-') + `main-menu-nav`} />
          <Navbar.Offcanvas
            id={(expand ? '' : 'mobile-') + `main-menu-nav`}
            aria-labelledby={`offcanvasNavbarLabel` + (expand ? '' : '-mobile')}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel` + (expand ? '' : '-mobile')} className="w-75">
                <Row>
                  <Col>
                    <Image src="/images/logo192" width={72} height={60} alt="Purple IO"></Image>
                  </Col>
                  <Col>
                    <span className="fs-4 lh-1 text-tertiary">Purple IO</span><br />
                    <small className="fs-6">UK Data Center</small>
                  </Col>
                </Row>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end align-items-center flex-grow-1 pe-3">
                <NavDropdown
                  id={`nav-app-expand`}
                  title={[
                    <FontAwesomeIcon key="app-icon" icon="th-large" className="text-tertiary me-2" />,
                    'Apps'
                  ]}
                  className="p-2"
                >
                  <NavDropdown.Item as={NavLink} to="/client">
                    <Image src="/images/sftpclient64" width={36} height={28} />&nbsp;
                    sFTP Client
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/chrome" className="align-items-center">
                    <span className="text-center d-inline-block" style={{width: 36}}>
                      <FontAwesomeIcon type="brand" icon="chrome" className="fs-3 yellow-text valign-middle" />
                    </span>&nbsp;
                    sFTP Client for Chrome
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={NavLink} to="/server">
                    <Image src="/images/sftpserver64" width={36} height={28} />&nbsp;
                    sFTP Server
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  id={`nav-hosting-and-servers-expand`}
                  title={[
                    <FontAwesomeIcon key="hosting-and-server-icon" icon="server" className="text-tertiary me-2" />,
                    'Hosting & Servers'
                  ]}
                  className="p-2"
                >
                  <NavDropdown.Item as={NavLink} to="/hosting">
                    All Hosting &amp; Server Plans
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={NavLink} to="/hosting/shared/standard">
                    <span className="text-center d-inline-block" style={{width: 30}}>
                      <FontAwesomeIcon icon="cloud" className="text-tertiary me-2" />
                    </span>
                    Standard Hosting
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/hosting/shared/premium">
                    <span className="text-center d-inline-block" style={{width: 30}}>
                      <FontAwesomeIcon icon="cubes" className="text-tertiary me-2" />
                    </span>
                    Premium SSD Hosting
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={NavLink} to="/hosting/servers/virtual-private-servers">
                    <span className="text-center d-inline-block" style={{width: 30}}>
                      <FontAwesomeIcon icon="server" className="text-tertiary me-2" />
                    </span>
                    Virtual Private Servers
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/hosting/servers/dedicated-servers">
                    <span className="text-center d-inline-block" style={{width: 30}}>
                      <FontAwesomeIcon icon="hdd" className="text-tertiary me-2" />
                    </span>
                    Dedicated Servers
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/hosting/servers/co-location">
                    <span className="text-center d-inline-block" style={{width: 30}}>
                      <FontAwesomeIcon icon="globe-stand" className="text-tertiary me-2" />
                    </span>
                    Co-Location Services
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  id={`nav-help-and-support-expand`}
                  title={[
                    <FontAwesomeIcon key="help-and-support-icon" icon="life-ring" className="text-tertiary me-2" />,
                    'Support'
                  ]}
                  className="p-2"
                >
                  <NavDropdown.Item href="https://help.purpleio.uk">
                    <FontAwesomeIcon icon="book" className="text-tertiary me-2" />
                    App Documentation
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="https://community.purpleio.uk">
                    <FontAwesomeIcon icon="comments" className="text-tertiary me-2" />
                    Community Forums
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={NavLink} to="/company/contact">
                    <FontAwesomeIcon icon="envelope" className="text-tertiary me-2" />
                    Contact Purple IO
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={NavLink} to="/order">
                  <Button variant="outline">
                    <FontAwesomeIcon icon="shopping-cart" className="white-text me-2" />
                    <Badge>{totalOrderQty > 1000 ? '1000+' : totalOrderQty}</Badge>
                  </Button>
                </Nav.Link>
                <Nav.Link href="https://account.purpleio.uk/auth/login">
                  <Button variant="outline-tertiary">
                    <FontAwesomeIcon icon="sign-in" className="me-2" />
                    Login
                  </Button>
                </Nav.Link>
                <Nav.Link href="https://account.purpleio.uk/auth/register">
                  <Button variant="tertiary" className="text-primary">
                    <FontAwesomeIcon icon="user-edit" className="me-1" />
                    Create Account
                  </Button>
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    ))}
  </>;
};

export default Header;
