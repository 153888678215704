import React from 'react';

const FontAwesomeIcon = ({
  type = 'duo',
  icon,
  className
}) => {
  let iconClass;

  switch (type) {
    case 'duo':
      iconClass = 'fad';
      break;
    case 'brand':
      iconClass = 'fab';
      break;
    case 'solid':
      iconClass = 'fas';
      break;
    case 'regular':
      iconClass = 'far';
      break;
    default:
      iconClass = 'fa';
      break;
  }

  return <span
    className={iconClass + ' fa-' + icon.replace('purple-text', 'text-tertiary') + ' ' + className}
  ></span>
};

export default FontAwesomeIcon;
